<template>
  <div style="background-color: white !important">
    <div id="Report Cover" class="report-cover">
      <img
        :src="require('../assets/report_cover.jpg')"
        style="width: 100%; height: 300px"
        class="my-8"
      />
      <div class="report-title mx-4">DIY BizValu™ Estimator</div>
      <div class="mb-12 report-title mx-4">ESTIMATED PRICING REPORT</div>
      <div class="mx-4 d-flex flex-row justify-space-between">
        <div class="d-flex flex-column">
          <div class="report-subtitle">
            <!-- DIY BizValue &trade; Estimator <br />
            ESTIMATED VALUATION REPORT <br /> -->
            {{ cashFlowData.company_name }} <br />
            {{ this.curDate }}
          </div>
          <div style="margin-top: 100px">
            Presented to: {{ cashFlowData.customer }} <br />
            {{ this.cashFlowData.email }}
          </div>
        </div>
        <div class="d-flex flex-column py-4">
          <img
            :src="require('../assets/peterson.png')"
            style="width: 250px; height: 250px"
          />
        </div>
      </div>
      <div class="d-print-none diy-report-actions d-flex flex-row-reverse">
        <b-button-group>
          <b-button
            class="mx-2"
            id="printPageButton"
            @click="printPage"
            variant="info"
            >Print</b-button
          >
          <b-button
            class="mx-2"
            id="goBackButton"
            @click="goBack"
            variant="primary"
            >Back</b-button
          >
          <b-button
            class="mx-2"
            id="logOutButton"
            @click="logOut"
            variant="success"
            >Log Out</b-button
          >          
        </b-button-group>
        
      </div>
    </div>

    <div id="Summary" class="report-pag">
      <div class="section-header-ribbon">SUMMARY</div>
      <p style="margin-top: 105px"></p>
      <div class="section-content">
        <div>
          The information found in this report is based on the self-reported
          information provided:
        </div>
        <div class="d-flex flex-row my-6">
          <div class="flex-column">
            <div>By:</div>
            <div>Email:</div>
            <div>On:</div>
            <div>Business:</div>
          </div>
          <div class="flex-column ml-6">
            <div>{{ cashFlowData.customer }}</div>
            <div>{{ cashFlowData.email }}</div>
            <div>{{ this.curDate }}</div>
            <div>{{ cashFlowData.company_name }}</div>
          </div>
        </div>
        <p style="margin-top: 25px"></p>
        <div class="mb-4">
          Based on this input, we will provide an estimated value of the
          business below. Importantly, we will point out some disclaimers in the
          following pages, along with a significant explanation of how this
          value was calculated.
        </div>
        <div class="mb-4">
          While the number is important to know, understanding HOW the number
          was calculated may be even more important. We hope you will study very
          carefully the supporting information. To us, this is where the real
          value lies.
        </div>
        <div class="mb-4">
          Based on the input provided, we estimate the value of the business to
          be:
        </div>
        <div class="mb-4 d-flex flex-row">
          <div class="flex-column">
            <div>Low Range:</div>
            <div>High Range:</div>
            <div>Average Value:</div>
          </div>
          <div class="flex-column ml-6">
            <div>{{ usCurrency.format(cashFlowData.low_biz_value) }}</div>
            <div>{{ usCurrency.format(cashFlowData.high_biz_value) }}</div>
            <div>{{ usCurrency.format(cashFlowData.avg_biz_value) }}</div>
          </div>
        </div>
        <p style="margin-top: 25px"></p>
        <div class="mb-4">
          This may be exactly what you expected, or significantly higher or
          lower. In addition to the explanation of the how these numbers were
          calculated (and why that is important), in the following pages, we
          also, based on the input you provided, include a number of suggestions
          in this report for how to improve this number.
        </div>
        <div>
          We urge you to read the remainder of the report very carefully.
        </div>
      </div>
      <footer>
        Copyright 2022. www.ChadPeterson.com. All rights reserved.
      </footer>
    </div>

    <div id="TOC" class="report-page">
      <div class="section-header-ribbon">TABLE OF CONTENTS</div>
      <p style="margin-top: 65px"></p>
      <div class="section-content">
        <div class="my-2">Summary</div>
        <div class="my-2">Table of Contents</div>
        <div class="my-2">Disclaimers</div>
        <div class="my-2">Valuation Methodology</div>
        <div class="my-2">Your Cashflow</div>
        <div class="my-2">Your Valuation Multiple</div>
        <div class="my-2">The Valuation Estimate</div>
        <div class="my-2">A Simple Buyer's Scenario</div>
        <div class="my-2">Suggested Improvements</div>
        <div class="my-2">A Simplified QSI Model Estimate</div>
        <div class="my-2">Next Steps</div>
        <div class="my-2">About this Report</div>
      </div>
    </div>

    <div id="Disclaimers" class="report-page">
      <div class="section-header-ribbon">DISCLAIMERS</div>
      <div class="section-content">
        <div class="my-4">
          The value of a business is a highly subjective matter with an
          unlimited number of methods, with highly varying levels of complexity
          and range to estimated values.
        </div>
        <div class="my-4">
          The method used in this report can be characterized as the “Multiple
          of Owner’s Cashflow” method. And while we find this method to be an
          accurate valuation method, as this this line of thinking heavily
          influences banking decisions, it is only one of a huge number of
          methods for valuation.
        </div>
        <div class="my-4">
          This report relies on one method of valuation, and there are strong
          reasons why we selected it. However, there are other methods of
          valuation.
        </div>
        <div class="my-4">
          Importantly, the data used in the calculation was self-reported and
          NOT audited. While care was taken to explain how to calculate the
          input numbers, errors may have been made (intentionally or
          unintentionally) by the individual who input the data. Because of the
          “self-reported” nature of the data, the report’s calculations and
          suggestions are only as good as the input data provided.
        </div>
        <div class="my-4">
          The data provided in the report was entered into computer formulas and
          was not scrutinized.
        </div>
        <div class="my-4">
          Similarly, this report was not provided by a certified business
          valuator. Thus, it should not be used for purposes of valuing a
          business for purposes of litigation, or of actually selling a
          business.
        </div>
        <div class="my-4">
          The data is provided from a management level only.
        </div>
        <div class="my-4">
          This means the data provides information to the owner / manager of the
          business for purposes of improving the performance of the company.
        </div>
        <div class="my-4">
          For simplicity, data was only collected on this business for one year.
          In a more formal valuation, multiple years of historical data will be
          analyzed.
        </div>
        <div class="my-4">
          If the recipient of this report is considering selling the business,
          they should consult the advice of an expert business broker, and / or
          a certified business valuator.
        </div>
        <div class="my-4">
          If the recipient of this report is attempting to value the business in
          divorce proceedings, both legal counsel and a certified business
          valuator should be consulted. This report is NOT to be used in this
          circumstance.
        </div>
        <div class="my-4">
          Unauthorized uses of this report are prohibited. Recipients of the
          report must acknowledge this valuation estimate and related consulting
          recommendations are strictly for purposes of management evaluating the
          input, determining its value and/or use in the specific business
          circumstance, and making decisions based on such inputs.
        </div>
        <div class="my-3">
          This report does not remove the personal responsibility business
          owners / managers have in evaluating information for their business
          and making informed, adult decisions, and being responsible for the
          consequences of such decisions.
        </div>
      </div>
    </div>

    <div id="VALUATION-METHODOLOGY" class="report-page">
      <div class="section-header-ribbon">VALUATION METHODOLOGY</div>
      <div class="section-content">
        <div class="my-4">
          As mentioned above, there are a number of different ways to value a
          business.
        </div>
        <div class="my-4">
          The method used in this report is the “Multiple of Owner’s Cashflow”
          method.
        </div>
        <div class="mb-4 mt-6 page-break-avoid">
          This is a commonly used method in transactions for closely-held
          business for the following reasons:
          <ul class="my-3 mx-5">
            <li>
              It is a reasonably simple method of calculation based on readily
              available financial statements of the company.
            </li>
            <li>
              Much of the information required for its calculation can be
              verified by income tax returns, which tends to increase the
              validity of the information.
            </li>
            <li>
              It accounts for the fact that many business owners take advantage
              of tax deductions through the business, which are legitimate and
              valid, but that may be more for personal benefit than for the
              business.
            </li>
            <li>
              It mirrors the decision-making process of banks, which has a
              significant impact on what Buyers can afford to pay for a
              business. Banks will evaluate the company’s cashflow and consider
              this data in relation to the required bank payments for a loan to
              support the purchase of that business. The banking term for this
              is “debt service coverage ratios.” Simply put, banks want to
              ensure their borrower can make the bank payments from the cashflow
              of the business. Because a very strong majority of closely-held
              businesses are purchased with bank financing, including bank debt
              financing up to 90% of the purchase price in many cases, we find
              that the bank rules are exceptionally important to understand.
            </li>
            <li>
              The method calculates a number of the owners true financial
              benefit and places the value on the business based around this.
            </li>
            <li>
              The methods of calculating Owner’s Cashflow (or SDE) are generally
              acknowledged by banks, the U.S. Small Business Administration, the
              Certified Public Accountants (CPA) community, and the business
              brokerage industry.
            </li>
          </ul>
        </div>
        <div class="my-4">
          The “Multiple of Owner’s Cashflow” method is also referred to as
          “Multiple of Seller’s Discretionary Earnings or SDE”
        </div>
        <div class="my-4">
          The method estimates the value of a business based on two key numbers.
          First, the Owner’s Cashflow (again, also referred to as “Sellers
          Discretionary Earnings” or SDE). And, secondly, a Valuation Multiple.
        </div>
        <div class="my-4">
          Owner’s Cashflow (or SDE) is calculated by adding together the
          following elements:
          <ul class="my-3 mx-5">
            <li>Business Net Profits</li>
            <li>Owner’s Salary + Taxes</li>
            <li>Amortization Expense</li>
            <li>Depreciation Expense</li>
            <li>Interest Expense</li>
            <li>Non-Recurring Expenses</li>
            <li>Owner’s Perks</li>
          </ul>
        </div>
        <div class="my-3 page-break-before">
          These numbers are calculated on an ANNUAL basis.
        </div>
        <div class="my-3 page-break-avoid">
          Owner’s Perks is a wide category that can encompass a number of
          things. Items which are generally views as acceptable “add-backs” for
          Owner’s Perks include things such as the following:
          <ul class="my-3 mx-5">
            <li>
              Owner’s automobile expenses, including gas, repairs, insurance and
              maintenance; this also includes the owner’s family
            </li>
            <li>Owner’s retirement contributions from the company</li>
            <li>Owner’s—and family’s—health insurance</li>
            <li>
              Memberships, such as country clubs or gyms for the owner’s benefit
            </li>
            <li>Charitable donations</li>
            <li>
              Dining, entertainment, and travel which is more for the owner’s
              benefit than the company
            </li>
            <li>
              Any rent charged to the company for real estate owned by the owner
              that is in excess of market rental rates
            </li>
          </ul>
        </div>
        <div class="my-4">
          Once the SDE or Owner’s Cashflow is known, it is multiplied by a
          Valuation Multiple to determine company value.
        </div>
        <div class="my-4">
          Simply, for illustrative purposes, if the Owner’s Cashflow is $250,000
          and a 3X multiple is used, the business value is calculated as
          follows:
        </div>

        <div class="my-4">
          Owners Cashflow X (times) Valuation Multiple = Business Valuation. In
          this example, 250,000 X 3 = $750,000
        </div>

        <div style="page-break-inside: avoid" class="my-4">
          Common multiples in closely-held business transactions where
          traditional bank financing will be involved range from 1X to 5X of
          Cashflow, with 3X being a very typical number. In most cases, a 3X
          multiple will be used as a benchmark number.
        </div>
        <div class="my-4">
          To reiterate, the limits of business Valuation Multiple are often
          dictated by lending terms. As the Valuation Multiple is closely
          related to the “Debt Service Coverage” analyses which drive bank
          lending decisions. Thus, banks prefer to limit Valuation Multiples to
          3X and rarely exceed 5X in typical settings.
        </div>
        <div class="my-4 page-break-before">
          So what drives the difference between a 1X, 3X, and 5X Valuation
          Multiple? Answers to the following questions impact the valuation
          multiple?
          <ul class="my-3 mx-5">
            <li>
              Compared to typical businesses where Owners Cashflow averages
              around $200,000, is the business Cashflow significantly above or
              below average?
            </li>
            <li>What are the revenue trends in the business?</li>
            <li>
              Is the ratio of Cashflow percent of Sales weak or strong compared
              to others?
            </li>
            <li>
              Is the business in a strong/ desirable industry compared to a weak
              / dying one?
            </li>
            <li>Is the business unique or proprietary?</li>
            <li>Does the business have any protected intellectual property?</li>
            <li>Has the company been in business for a short or long time?</li>
            <li>
              Is labor generally available for the company / industry or is
              there a shortage?
            </li>
            <li>
              Has the company developed a strong management team who will stay
              on during the transition to help the new owner?
            </li>
            <li>How difficult will it be for a new owner to take over?</li>
            <li>
              How much is the new owner buying a business versus just purchasing
              a job?
            </li>
            <li>
              Has the company established strong marketing and/or position in
              the market?
            </li>
          </ul>
        </div>
        <div class="my-4">
          In this valuation methodology, all assets (such as equipment and
          inventory) are included in the calculation, as it is viewed that they
          are necessary to generate the Owner’s Cashflow.
        </div>
        <div class="my-4">
          Real Estate, however, is tracked separately and is added at its
          current market value rate to the overall business sales price,
          assuming the real estate is included in the transaction.
        </div>
        <div class="my-4">
          The questions answered in the DIY Biz Value input wizard were
          collected to answer many of the questions above.
        </div>
      </div>
    </div>

    <div class="report-page" id="YOUR CASHFLOW">
      <div class="section-header-ribbon">YOUR CASHFLOW</div>
      <div class="section-content">
        <div class="mb-4">
          Based upon the inputs you provided, your Owner’s Cashflow is as
          follows:
        </div>
        <div class="d-flex flex-row my-4">
          <div class="flex-column">
            <div>Company:</div>
            <div>Year:</div>
          </div>
          <div class="flex-column ml-5">
            <div>{{ cashFlowData.company_name }}</div>
            <div>{{ cashFlowData.work_year }}</div>
          </div>
        </div>
        <v-simple-table class="report-table page-inside-break-avoid mb-4">
          <template>
            <thead style="color: azure">
              <tr>
                <th class="header-black-and-white">CASHFLOW ELEMENTS</th>
                <th class="header-black-and-white" style="text-align: right">
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Net Income / Profits
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.net_income) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Owner Salary & PR Taxes
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.salary_taxes) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Amortization & Depreciation
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.amortization) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Interest Expense
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.int_exp) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Non Recurring</td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.non_recur_exp) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Total Owner's Perks
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.ownerperks) }}
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="
                    border-right: 1px solid #c4c4c4;
                    background-color: black;
                    color: white;
                  "
                >
                  TOTAL OWNER'S CASHFLOW
                </td>
                <td
                  style="
                    background-color: black;
                    color: white;
                    text-align: right;
                  "
                >
                  {{ usCurrency.format(cashFlowData.total_cash_flow) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <p style="margin-top: 45px"></p>
        <div class="my-4 mt-7">
          The details for the “Total Owners Perks” calculation is provided on
          the next page.
        </div>
        <div class="my-4">
          The “Total Owner’s CashFlow” number above is the one used in the
          calculation of business value.
        </div>
        <div class="my-4" v-if="cashFlowData.realestate > 0">
          During the input wizard you indicated that there was a value to the
          company owned real estate. This number is NOT included in the CashFlow
          calculation, nor is it multiplied by the valuation multiple. The
          market value of the real estate is simply added to the calculated
          business value. The value you indicated for the Real Estate is below:
        </div>
        <div class="my-4" v-if="cashFlowData.realestate > 0">
          Real Estate Value = {{ usCurrency.format(cashFlowData.realestate) }}.
        </div>
        <div class="my-4 page-break-before">
          The following summarizes the information you provided to calculate the
          “Total Owner’s Perks” line on the prior page.
        </div>
        <v-simple-table class="report-table page-inside-break-avoid">
          <template>
            <thead style="color: azure">
              <tr>
                <th class="header-black-and-white">OWNER'S PERKS ELEMENTS</th>
                <th class="header-black-and-white" style="text-align: right">
                  AMOUNT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Car Payments + Expenses
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.car_payments) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Car Insurance</td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.car_insurance) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Health Insurance
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.health_insurance) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Life Insurance</td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.life_insurance) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Retirement Accounts
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.ira) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Charitable Contributions
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.charitable_contrib) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Meals & Entertainment
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.meals) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Travel</td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.travel) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Memberships</td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.memberships) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Family Compensation
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.family_comp) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Miscellaneous Expenses
                </td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.other) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Rent Variance</td>
                <td style="text-align: right">
                  {{ usCurrency.format(cashFlowData.real_rent) }}
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="
                    border-right: 1px solid #c4c4c4;
                    background-color: black;
                    color: white;
                  "
                >
                  TOTAL OWNER'S PERKS
                </td>
                <td
                  style="
                    background-color: black;
                    color: white;
                    text-align: right;
                  "
                >
                  {{ usCurrency.format(cashFlowData.ownerperks) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <div class="report-page" id="YOUR VALUATION MULTIPLE">
      <div class="section-header-ribbon">YOUR VALUATION MULTIPLE</div>
      <div class="section-content">
        <div class="my-4">
          In this valuation method, we multiply the Owner’s CashFlow times a
          Valuation Multiple. Then we add in any owned real estate which will be
          included in the transaction.
        </div>
        <div class="my-4">
          As mentioned above, typical valuation ranges in closely-held companies
          are 1X to 5X, with the strong majority of transactions being at or
          near 3X. As explained, this is largely based on lending rules that
          banks utilize in relation to the buyers ability to finance the
          purchase and make the bank payment with the expected Cashflow from the
          business.
        </div>
        <div class="my-4">
          Below, we provide a low range multiple and a high range multiple as an
          estimate of the range of business value.
        </div>
        <div class="my-4">
          Based on the input provided, we would estimate the Valuation Multiple
          for your business to be:
        </div>
        <div class="d-flex flex-row my-4">
          <div class="flex-column">
            <div>Low Range Valuation Multiple</div>
            <div>High Range Valuation Multiple</div>
            <div>Average Valuation Multiple</div>
          </div>
          <div class="flex-column ml-5">
            <div>= {{ cashFlowData.low_multiple }}</div>
            <div>= {{ cashFlowData.high_multiple }}</div>
            <div>= {{ cashFlowData.avg_multiple }}</div>
          </div>
        </div>
        <div class="my-4">
          These multiples were calculated based on following factors from your
          input. A score is provided with each factor to provide a weighting
          factor. If a number is over 100, it is an element that is raising your
          multiple; items below 100 are having a negative impact on it.
        </div>

        <p style="margin-top: 55px"></p>
        <div class="my-4 table-heading">
          FACTORS IMPACTING VALUATION MULTIPLE
        </div>

        <v-simple-table
          class="report-table page-inside-break-avoid report-table"
        >
          <template>
            <thead style="color: azure">
              <tr>
                <th class="header-black-and-white">FACTOR</th>
                <th class="header-black-and-white" style="text-align: right">
                  WEIGHT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in valuationData" :key="item.item" class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  {{ item.title }}
                </td>
                <td style="text-align: right">
                  {{ item.weight }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-10 mb-5 page-break-before table-heading">
          FACTORS INCREASING VALUATION MULTIPLE
        </div>
        <div v-for="item in increasedData" :key="item.item">
          <li style="font-weight: 800; margin-top: 35px">
            {{ item.title }}
          </li>
          <div class="my-1">
            {{ item.explanation }}
          </div>
        </div>

        <div class="mt-10 mb-5 page-break-before table-heading">
          FACTORS DECREASING VALUATION MULTIPLE
        </div>
        <div class="my-4" v-for="item in decreasedData" :key="item.item">
          <li style="font-weight: 800; margin-top: 35px">
            {{ item.title }}
          </li>
          <div>
            {{ item.explanation }}
          </div>
        </div>
      </div>
    </div>

    <div class="report-page" id="THE VALUATION ESTIMATE">
      <div class="section-header-ribbon">THE VALUATION ESTIMATE</div>
      <div class="section-content">
        <div class="my-4">
          The prior information all comes together to calculate an approximate
          value for your business.
        </div>
        <div class="my-4">
          Here are the factors that contribute to the valuation estimate below:
        </div>

        <ul class="my-3 mx-5">
          <li>
            Total Owner’s Cashflow =
            {{ usCurrency.format(cashFlowData.total_cash_flow) }}
          </li>
          <li>
            Low Range Valuation Multiple = {{ cashFlowData.low_multiple }}
          </li>
          <li>
            High Range Valuation Multiple = {{ cashFlowData.high_multiple }}
          </li>
          <li>Average Valuation Multiple = {{ cashFlowData.avg_multiple }}</li>
          <li>
            Real Estate included in the Transaction =
            {{ usCurrency.format(cashFlowData.realestate) }}
          </li>
          <li>
            Low Range Valuation =
            {{ usCurrency.format(cashFlowData.low_biz_value) }}
          </li>
          <li>
            High Range Valuation =
            {{ usCurrency.format(cashFlowData.high_biz_value) }}
          </li>
          <li>
            Average Valuation =
            {{ usCurrency.format(cashFlowData.avg_biz_value) }}
          </li>
        </ul>
        <div class="my-4">
          Please recall all of the items highlighted above, including the
          explanation of the valuation methodology and the various disclaimers.
        </div>
        <div class="my-4">
          The following pages provide additional explanation and suggestions on
          how to improve the value of your company.
        </div>
      </div>
    </div>

    <div class="report-page" id="A SIMPLE BUYER'S SCENARIO">
      <div class="section-header-ribbon">A SIMPLE BUYER'S SCENARIO</div>
      <div class="section-content">
        <div class="my-4">
          Business owners have a variety of reactions to seeing the value of the
          business, and they run a deep gamut of reaction. Some are amazed that
          the business is worth anything and wonder who would buy it. Others are
          devastated that the number is so low.
        </div>
        <div class="my-4">
          We find it is helpful to look at the purchase price through the lens
          of the buyer, and ultimately of the Bank. This is particularly wise if
          you are looking at ways to improve the sales price of the business.
          This is a basic “understand your customer” type of logic.
        </div>
        <div class="my-4">
          Based on the input provided and shared in the report previously, the
          business value and cashflow are estimated, based on your input, to be
          the following:
        </div>
        <div class="my-4 d-flex flex-row">
          <div class="flex-column">
            <div>Business Value</div>
            <div>Owners Annual Cash Flow</div>
          </div>
          <div class="flex-column ml-4">
            <div>= {{ usCurrency.format(cashFlowData.avg_biz_value) }}</div>
            <div>= {{ usCurrency.format(cashFlowData.total_cash_flow) }}</div>
          </div>
        </div>
        <div class="my-4">
          Assuming a 10% down payment is required for a borrower to buy the
          business, and 8% interest on a 10 year loan. (Note: typically banks
          will require SBA support to loan at terms this favorable; without the
          SBA, making the numbers work for Buyers is even more of a challenge.)
          Based on these assumptions, we can calculate the following:
        </div>
        <div class="my-4 d-flex flex-row">
          <div class="flex-column">
            <div>Down Payment Required (at 10%)</div>
            <div>Amount to Borrow (90%)</div>
            <div>Annual Debt Service Payments</div>
            <div>Cashflow After Debt Service</div>
            <div>Debt Service as a % of Cashflow</div>
          </div>
          <div class="flex-column ml-4">
            <div>= {{ usCurrency.format(cashFlowData.down_payment) }}</div>
            <div>= {{ usCurrency.format(cashFlowData.amt_to_borrow) }}</div>
            <div>= {{ usCurrency.format(cashFlowData.debt_service) }}</div>
            <div>= {{ usCurrency.format(cashFlowData.cashflow_aft_debt) }}</div>
            <div>
              =
              {{
                parseFloat(cashFlowData.debt_svc_percent * 100).toFixed() + "%"
              }}
            </div>
          </div>
        </div>
        <div class="my-4">
          (Note: if real estate is included, a longer loan term may be used for
          the real estate portion, but the primary point is still valid.)
        </div>
        <div class="my-4">
          It makes sense to sit with those numbers for a minute. How do you feel
          about the thought of putting down the down payment, making the
          payments for 10 years, and running the business / being rewarded with
          the cashflow number less the debt service? For many potential Sellers,
          this is an illuminating observation.
        </div>
        <div class="my-4">We will part with this comment.</div>
        <div class="my-4">
          Generally, the values placed on small businesses in transactions only
          take into account the cashflow and how much of a payment can be
          supported by the cashflow of the business. The banks have established
          loan parameters and rules, and generally, do not want to bet on
          significant growth in Cashflow to support the payment. As a rule of
          thumb, banks tend to look negatively at a situation where the borrower
          will be forced to grow the Cashflow more than 10% per year.
        </div>
        <div class="my-4">
          Similarly, they also know the borrower needs to earn a living. If the
          borrower is giving most of the businesses Cashflow to the bank to make
          the payment, they will eventually tire of this. And the bank needs to
          ensure the business buyer is motivated enough to stick with the
          business during the entire duration of the loan.
        </div>
        <div class="my-4">
          This is hard reality. But here is one thing we genuinely believe: the
          current rules on valuation multiple do not provide sellers with enough
          reward for the hard work, investment, and stress endured. Frankly, we
          believe multiples favor the banks and buyers.
        </div>
        <div class="my-4">
          So we understand and can relate if you feel the valuation feels low.
          We have a sister company who is a broker and we most certainly wish we
          could sell businesses for double what we do. This would increase our
          reward as well. The fact of the matter is that these banking rules,
          which are calculated into the acceptable range of Valuation Multiples
          are a key driver.
        </div>
        <div>
          Telling you the truth about the ways banks look at your business may
          be a tough reality to face, but we believe this is better than telling
          you a fairy tale. We believe in working with reality and using its
          rules to your advantage.
        </div>
      </div>
    </div>

    <div class="report-page" id="SUGGESTED IMPROVEMENTS">
      <div class="section-header-ribbon">SUGGESTED IMPROVEMENTS</div>
      <div class="section-content">
        <div class="my-4">
          The most common question we receive is, “How much is my business
          worth.” The second most common question is, “How do I grow the value
          of the business.” Based on the prior input, we are happy to present a
          number of solutions regarding growing your business value.
        </div>
        <div class="my-4">
          The first section are recommendations based specifically on the
          questions you answered. The second are general suggestions applicable
          to everyone.
        </div>
        <div class="mt-10 mb-5">
          <span class="table-heading">IMPROVEMENTS BASED ON YOUR INPUTS</span>
          <div class="my-6" v-for="item in valuationData" :key="item.item">
            <ul class="my-3 mx-5">
              <li>
                {{ item.suggestion_1 }}
              </li>
              <li class="my-2">
                {{ item.suggestion_2 }}
              </li>
              <li class="my-2">
                {{ item.suggestion_3 }}
              </li>
            </ul>
          </div>
        </div>
        <div class="page-break-before mt-10 mb-5">
          <span class="table-heading"
            >GENERALLY SUGGESTED WAYS TO IMPROVE VALUATION</span
          >
          <p style="margin-top: 35px"></p>
          <ul class="my-3 mx-5">
            <li>
              Don’t put your head in the sand about valuation – not knowing is
              not acceptable, neither is being mad about the reality around the
              numbers. Look at reality square in the face and take productive
              actions around it.
            </li>
            <li>
              The valuation method described in this report is based on two
              variables. One is Owner’s Cashflow. The second is the Valuation
              Multiple. Develop strategies which specifically work to make
              improvements in each of these two areas. Basically, given this
              formula, there are only two ways to improve the number. Work on
              both!
            </li>
            <li>
              Set up tracking mechanisms to monitor Cashflow and estimated
              Valuation. These numbers do not show up on common financial
              statements, and they are extremely important for a business owner
              to know.
            </li>
            <li>
              Get serious about your exit planning WELL before you are ready to
              exit. Most people greatly underprepare for this important aspect
              of managing their business.
            </li>
            <li>
              Place a higher value on ENTERPRISE VALUE than on sales, employee
              count or even profitability. Most business owners keep score with
              the incorrect metrics. Publicly traded companies monitor stock
              price like a hawk and respond to spikes and dips. Small business
              people are too often very oblivious about these matters.
            </li>
            <li>
              Start to think about your sales pitch now, and start to develop
              how you would answer important questions about the company. As its
              owner, you know the questions you will struggle to answer. Assume
              a smart buyer will uncover all of the “warts” in your business.
              Work to address them now.
            </li>
            <li>
              Study the things which impact the valuation multiple the most (we
              have highlighted a number of them in the suggestions above,
              particularly those you have indicated are likely impacting your
              company.) Get very aggressive about working on the multiple and
              the ways to improve it.
            </li>
            <li>
              Watch investments which sacrifice too much today for “doing the
              right thing” or “winning in the future.” Too often, business
              owners bet far too much on building value in the future at the
              direct cost of the business value—and health—today.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="report-page" id="A SIMPLIFIED QSI MODEL ESTIMATE">
      <div class="section-header-ribbon">A SIMPLIFIED QSI MODEL ESTIMATE</div>
      <div class="section-content">
        <div class="my-4">
            Quantum Stack Investing (QSI)  is an advanced wealth building methodology and a proprietary business management system from Chad Peterson and his related companies. Essentially, this approach taps into the asset potential of your business and utilizes traditional banking rules to open up significant income and wealth building power. 
        </div>
        <div class="my-4">
          Based upon the information you have provided; we are able to provide a very rough approximation of income and wealth returns from utilizing QSI in your business. Please see the explanation below. 
        </div>
        <div class="my-4">
          To explain QSI in a very simple manner, the business owner sells their current business and uses the proceeds to purchase a next, larger business, or what we call an NLB. This creates surprisingly high multiplying effects.          
        </div>
        <div class="my-4">
          While this simple explanation provides the backdrop for the system in a single sentence, implementing it is more detailed and precise. We have written several books on the topic. Utilizing QSI can transform the way a business owner buys, sells, and manages their company.        
        </div>
        <div class="my-4">
          Here is the estimated Net Cashflow + Net Valuation returns of QSI over three different time frames:
        </div>
        <p style="margin-top: 30px;"></p>
        <div>
          <table class="report-table-center page-inside-break-avoid report-table" style="margin-left: auto; margin-right: auto;">
            <template>
              <thead style="color: azure;">
                <tr>
                  <th class="header-white-on-black" style="text-align: left; width: 90px;">TIME</th>
                  <th class="header-white-on-black" style="text-align: left; width: 125px;">BASELINE</th>
                  <th class="header-white-on-black" style="text-align: left; width: 125px;">CONSERVATIVE</th>
                  <th class="header-white-on-black" style="text-align: left; width: 125px;">AGGRESSIVE</th>                
                </tr>
              </thead>
              <tbody>
                <tr class="td-small">
                  <td style="border-right: 1px solid #c4c4c4">
                    5 Years
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*6.8) }}
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*13.2) }}
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*25.6) }}
                  </td>                               
                </tr>
                <tr class="td-small">
                  <td style="border-right: 1px solid #c4c4c4">
                    10 Years
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*12.1) }}
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*38.4) }}
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*68.4) }}
                  </td>                               
                </tr>
                <tr class="td-small">
                  <td style="border-right: 1px solid #c4c4c4">
                    15 Years
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*18.3) }}
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*74.2) }}
                  </td>
                  <td style="text-align: right">
                    {{ usCurrency.format(this.cashFlowData.total_cash_flow*121.1) }}
                  </td>                               
                </tr>              
              </tbody>
            </template>
          </table>
        </div>
 
        <p style="margin-top: 50px;"></p>
        <div class="my-4">
          NOTE: Many of our clients do NOT use QSI, so it is not a requirement. Rather, we share this information to let you know there is an alternative. Too many business owners, in our experience, find themselves almost trapped in their companies waiting to sell for the single, one big exit just before retirement. Not only is this belief flawed and extremely exhausting, but it can cost the business owner the opportunity to grow significant wealth and income.         </div>
        <div class="my-4">
          The numbers above make some broad based assumptions and are only thumbnail approximations. They also look at QSI over three different time periods, as the length of time one utilizes QSI does have an impact on the returns calculation. In this simple analysis, we looked at 5 years, 10 years, and 15 years.         </div>
        <div class="my-4">
          Similarly, there are more and less aggressive approaches to QSI.  In this “thumbnail” model for estimating potential QSI returns, we provide both “conservative” and “aggressive” numbers.         </div>
        <div class="my-4">
          Finally, we also provide a baseline, which is if you were to hold your current business over the same time period. We find a QSI “stack”, as we call it, makes financial sense even if your investment horizon is as short as even three years.        </div>
        <div class="my-4">
          It is important to note that implementing QSI requires evaluating more in-depth assumptions (than we did in this case) along with specific financial modeling based on your individual situation. We provide this information only to give a rough estimate of the type of returns multiplication which may be possible with QSI and your business.         </div>
        <div class="my-4">
          (Of note, this very basic analysis only considers the value of the “going concern” and not any commercial real estate. If you have real estate the QSI numbers above do not take the value of the real estate into consideration, which would obviously impact this analysis.)        </div>
        <div class="my-4">
          For the right person, QSI can be a significant multiplier of wealth and income and provide for a “change of scenery” that many businesspeople seek. We love QSI because it allows business owners to move onto a next adventure and exit a business where they may be experiencing boredom and burnout. All this while simultaneously increasing their income and wealth.         </div>
        <div class="my-4">
          To learn more about QSI, please contact a member of the Peterson Acquisitions team, or visit our website. Specifically, our book, “The QSI Shift” addresses this topic directly. We also provide a free weekly Zoom session on the benefits of QSI.         </div>
       
      </div>
    </div>
    <div class="report-page" id="NEXT STEPS">
      <div class="section-header-ribbon">NEXT STEPS</div>
      <div class="section-content">
        <div class="my-4">
          <div>
            Great job in completing the input tracker and for reviewing this
            report! This was a lot of work and we hope you gained a great deal
            from it.
          </div>
          <div class="my-4">
            We suggest you also consider the following next steps:
            <ul class="my-3 mx-5">
              <li>
                Speak with one of our brokers – Our team members are trained on
                these matters and are experts at helping people understand and
                improve the value of their company. Even if you are not
                interested in selling today, we would love to help you
                understand. You can speak to one of our team members on this
                report at no cost.<span 
              ><a
                href="https://petersonacquisitions.com/"
                target="_blank"
                rel="noopener noreferrer"
                >  Click here to set up an appointment.</a
              ></span> 
              </li>

              <li>
                Learn about Quantum Stack Investing™ - We have a method which
                helps business owners significantly multiply their wealth and
                exit their companies much faster than without this approach.
                This approach uses a unique approach to buying and selling
                companies and unlocking the asset power already in your current
                business. <span 
              ><a
                href="https://chadpeterson.com/qsi/"
                target="_blank"
                rel="noopener noreferrer"
                >  Click here </a
              ></span> to learn more including an invitation to a
                free weekly Zoom presentation.
              </li>

              <li>
                Create a plan – Exiting a business is no minor task. We can help
                you with this planning approach.  <span 
              ><a
                href="https://chadpeterson.com/"
                target="_blank"
                rel="noopener noreferrer"
                >  Click here </a
              ></span> to learn more.
              </li>

              <li>
                List your business – if you are interested in exiting, we can
                discuss how to make this happen. We are the best brokers in the
                country with many tools (like this) that can revolutionize the
                way you sell your business.  <span 
              ><a
                href="https://petersonacquisitions.com/"
                target="_blank"
                rel="noopener noreferrer"
                >  Click here </a
              ></span> to learn more.
              </li>

              <li>
                Hire us as a coach or consultant – Not ready to sell yet, but
                need assistance with managing these elements in your business?
                We help with this too. We are experts at small business
                transactions and we want to help you maximize the process – on
                your terms and on your timeline!  <span 
              ><a
                href="https://chadpeterson.com/coaching-consulting/"
                target="_blank"
                rel="noopener noreferrer"
                >  Click here </a
              ></span> to learn more.
              </li>
              <li>
                Start to think about your sales pitch now, and start to develop
                how you would answer important questions about the company. As
                its owner, you know the questions you will struggle to answer.
                Assume a smart buyer will uncover all of the “warts” in your
                business. Work to address them now.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="report-page" id="ABOUT THIS REPORT">
      <div class="section-header-ribbon">ABOUT THIS REPORT</div>
      <div class="section-content">
        <div class="my-4">
          This DIY BizValu™ Estimator report is a collaborative effort between
          two sister companies, both led by Chad Peterson, the country’s #1
          business broker.
        </div>
        <div class="my-4">
          <span 
              ><a
                href="https://petersonacquisitions.com/"
                target="_blank"
                rel="noopener noreferrer"
                >  Peterson Acquisitions </a
              ></span> is a nation-wide business brokerage which
          focuses on facilitation the purchase and sale of closely-held
          businesses, typically with revenue ranges between $500K and $100M.
        </div>

        <div class="my-4">
          <span 
              ><a
                href="https://chadpeterson.com/"
                target="_blank"
                rel="noopener noreferrer"
                >  www.ChadPeterson.com </a
              ></span> is a website with educational and consulting
          products and services to help people learn about buying and selling
          companies. Chad is the country’s foremost expert on the subject and
          the number of courses, books, and reference materials available from
          this site is incredible. Our proprietary Quantum Stack Investing™ or
          QSI program is featured on this website along with a number of tools
          to help with the buy-side and sell-side of closely-held business
          transactions.
        </div>

        <!-- <div v-if="cashFlowData.realestate > 0">
          During the input wizard you indicated that there was a value to the
          company owned real estate. This number is NOT included in the CashFlow
          calculation, nor is it multiplied by the valuation multiple. The
          market value of the real estate is simply added to the calculated
          business value. The value you indicated for the Real Estate is below:
        </div>
        <div v-if="cashFlowData.realestate > 0">
          Real Estate Value = {{ usCurrency.format(cashFlowData.realestate) }}.
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";

export default {
  name: "DIYLastGeneratedReport",
  directives: {
    mask,
  },
  created() {
    this.checkPermissions();
    
    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    this.getData();
  },
  data() {
    return {
      componentURL: `vwCashFlowDIY/?user_id=${this.$route.params.id}`,
      componentURL_base: `vwCashFlowDIY`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      usCurrency: null,
      curDate: this.$moment(String(Date())).format("MM/DD/YYYY"),
      menuID: localStorage.getItem("menuID"),
      currentDeal: localStorage.getItem("Deal"),
      currentUserID: localStorage.getItem("userid"),
      items: [],
      valuationData: [],
      increasedData: [],
      decreasedData: [],

      cashFlowData: {
        user_id: "",
        customer: "",
        email: "",
        company_name: "",
        work_year: "",
        total_valuation: "",
        low_multiple: "",
        high_multiple: "",
        avg_multiple: "",
        low_biz_value: "",
        high_biz_value: "",
        net_income: "",
        salary_taxes: "",
        amortization: "",
        int_exp: "",
        non_recur_exp: "",
        ownerperks: "",
        total_cash_flow: "",
        realestate: "",
        car_payments: "",
        car_insurance: "",
        health_insurance: "",
        ira: "",
        charitable_contrib: "",
        meals: "",
        travel: "",
        memberships: "",
        family_comp: "",
        other: "",
        real_rent: 0,
      },

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: " ",
        precision: 0,
        masked: false,
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  methods: {
    checkPermissions() {
      if(this.currentUserID !==this.$route.params.id){
        if (this.isAdmin == "false"){
          this.$router.push("/");
        }
      }       
    },

    printPage() {
      const tempTitle = document.title;
      document.title = "DIY BizValu Report.pdf";
      window.print();
      document.title = tempTitle;
    },

    getData() {
      CommonServices.getItem(
        "DIYLastGenerated/last-generated/?user_id=" + this.$route.params.id
      )
        .then((response) => {
          this.cashFlowData = JSON.parse(response.vwCashFlowDIY);
          this.decreasedData = JSON.parse(
            response.vwDecreasedValuationReportText
          );
          this.valuationData = JSON.parse(response.vwValuationData);
          this.increasedData = JSON.parse(
            response.vwIncreasedValuationReportText
          );
        })
        .catch(() => {
          this.snackbar = true;
          this.snackColor = "error";
          this.error = "Something went wrong";
        });
    },

    showIntro() {
      this.saveFormData();
      this.dlgTOC = false;
      this.$router.push(`/DIYWelcome/${this.$route.params.id}`);
    },

    showIntroVideo() {
      this.saveFormData();
      this.dlgTOC = false;
      this.$router.push(`/DIYIntroVideo/${this.$route.params.id}`);
    },

    showCashFlow13() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-3/${this.$route.params.id}`);
    },

    showCashFlow14() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-4/${this.$route.params.id}`);
    },

    showCashFlow15() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-5/${this.$route.params.id}`);
    },

    showCashFlow16() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-6/${this.$route.params.id}`);
    },

    showCashFlow17() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-7/${this.$route.params.id}`);
    },

    showCashFlow18() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-8/${this.$route.params.id}`);
    },

    showCashFlow19() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-9/${this.$route.params.id}`);
    },

    showCashFlow110() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-10/${this.$route.params.id}`);
    },

    showCashFlow111() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-11/${this.$route.params.id}`);
    },

    showCashFlow112() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-12/${this.$route.params.id}`);
    },

    showCashFlow113() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_1-13/${this.$route.params.id}`);
    },

    showCashFlow21() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-1/${this.$route.params.id}`);
    },

    showCashFlow22() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-2/${this.$route.params.id}`);
    },

    showCashFlow23() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-3/${this.$route.params.id}`);
    },

    showCashFlow24() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-4/${this.$route.params.id}`);
    },

    showCashFlow25() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-5/${this.$route.params.id}`);
    },

    showCashFlow26() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-6/${this.$route.params.id}`);
    },

    showCashFlow27() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-7/${this.$route.params.id}`);
    },

    showCashFlow28() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-8/${this.$route.params.id}`);
    },

    showCashFlow29() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-9/${this.$route.params.id}`);
    },

    showCashFlow210() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-10/${this.$route.params.id}`);
    },

    showCashFlow211() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-11/${this.$route.params.id}`);
    },

    showCashFlow212() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-12/${this.$route.params.id}`);
    },

    showCashFlow213() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_2-13/${this.$route.params.id}`);
    },

    showCashFlow31() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-1/${this.$route.params.id}`);
    },

    showCashFlow32() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-2/${this.$route.params.id}`);
    },

    showCashFlow33() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-3/${this.$route.params.id}`);
    },

    showCashFlow34() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-4/${this.$route.params.id}`);
    },

    showCashFlow35() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-5/${this.$route.params.id}`);
    },

    showCashFlow36() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-6/${this.$route.params.id}`);
    },

    showCashFlow37() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-7/${this.$route.params.id}`);
    },

    showCashFlow38() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-8/${this.$route.params.id}`);
    },

    showCashFlow39() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-9/${this.$route.params.id}`);
    },

    showCashFlow310() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-10/${this.$route.params.id}`);
    },

    showCashFlow311() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-11/${this.$route.params.id}`);
    },

    showCashFlow312() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-12/${this.$route.params.id}`);
    },

    showCashFlow313() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-13/${this.$route.params.id}`);
    },

    showCashFlow314() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-14/${this.$route.params.id}`);
    },

    showCashFlow315() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-15/${this.$route.params.id}`);
    },

    showCashFlow316() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_3-16/${this.$route.params.id}`);
    },

    showCashFlow41() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-1/${this.$route.params.id}`);
    },

    showCashFlow42() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-2/${this.$route.params.id}`);
    },

    showCashFlow43() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-3/${this.$route.params.id}`);
    },

    showCashFlow44() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-4/${this.$route.params.id}`);
    },

    showCashFlow45() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-5/${this.$route.params.id}`);
    },

    showCashFlow46() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-6/${this.$route.params.id}`);
    },

    showCashFlow47() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-7/${this.$route.params.id}`);
    },

    showCashFlow48() {
      this.dlgTOC = false;
      this.saveFormData();
      this.$router.push(`/DIY_4-8/${this.$route.params.id}`);
    },

    showTOC() {
      this.dlgTOC = true;
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style src="../css/styles.css" scoped></style>
<style scoped>
div {
  font-weight: 500;
  font-size: 18px;
  font-family: "Lato", sans-serif;
}

@media print {
  .page-break-before {
    margin-top: 1in !important;
    page-break-before: always;
  }
}
</style>
